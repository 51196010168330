var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pageAdministrationManagedStartups"
  }, [_vm.$appConfig.brand && _vm.$appConfig.brand.features.startup && _vm.$appConfig.brand.features.startup.isManager ? _c('v-card', {
    staticClass: "px-5 ml-sm-3 mt-10",
    attrs: {
      "disabled": _vm.disabled,
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('managedStartups')) + " "), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "info"
    },
    on: {
      "click": function ($event) {
        _vm.modalAddManagedStartup = true;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-plus-circle ")]), _vm._v(" " + _vm._s(_vm.$t('addStartup')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "info"
    },
    on: {
      "click": _vm.getManagedBrands
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1), _c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": `${_vm.$t('Search')}`,
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchManagedBrand,
      callback: function ($$v) {
        _vm.searchManagedBrand = $$v;
      },
      expression: "searchManagedBrand"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersManagedBrands,
      "items": _vm.managedStartups,
      "search": _vm.searchManagedBrand,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      },
      "options": _vm.optionsManagedBrands
    },
    scopedSlots: _vm._u([{
      key: `item.name`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "cursor-pointer",
          on: {
            "click": function ($event) {
              return _vm.viewManagedWarehouse(item._id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.name) + " ")])];
      }
    }, {
      key: `item.action`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-btn', {
          staticClass: "white--text",
          attrs: {
            "color": "primary",
            "title": _vm.$t('view')
          },
          on: {
            "click": function ($event) {
              return _vm.viewManagedWarehouse(item._id);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)];
      }
    }, {
      key: `item.created`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "cursor-pointer",
          on: {
            "click": function ($event) {
              return _vm.viewManagedWarehouse(item._id);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), 'long')) + " ")])];
      }
    }], null, true)
  })], 1)], 1) : _vm._e(), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "750px"
    },
    model: {
      value: _vm.modalAddManagedStartup,
      callback: function ($$v) {
        _vm.modalAddManagedStartup = $$v;
      },
      expression: "modalAddManagedStartup"
    }
  }, [_c('v-card', {
    attrs: {
      "min-height": "300px"
    }
  }, [_c('v-card-title', {
    staticClass: "headline"
  }, [_c('v-spacer'), _vm._v(" " + _vm._s(_vm.$t('addNewStartup')) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "ripple": false
    },
    on: {
      "click": function ($event) {
        _vm.modalAddManagedStartup = false;
      }
    }
  }, [_c('v-icon', [_vm._v("fas fa-times")])], 1)], 1), _c('v-card-text', [_c('formAddress', {
    ref: "managedStartupForm",
    attrs: {
      "address": _vm.managedStartup,
      "restricted-country-list": ['FR'],
      "is-valid-v-a-t": _vm.isValidVAT,
      "show-first-name": false,
      "show-last-name": false,
      "show-state": false,
      "show-phone-number": true,
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true,
      "allow-to-configure-v-a-t-later": true
    },
    on: {
      "validateVAT": _vm.validateVAT,
      "updated": _vm.updateManagedStartup
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-capitalize",
    attrs: {
      "disabled": _vm.managedStartupLoading,
      "loading": _vm.managedStartupLoading,
      "color": "success"
    },
    on: {
      "click": _vm.addManagedStartup
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }