<template>
  <div class="pageAdministrationManagedStartups">
    <v-card
      v-if="
        $appConfig.brand &&
          $appConfig.brand.features.startup &&
          $appConfig.brand.features.startup.isManager
      "
      :disabled="disabled"
      flat
      class="px-5 ml-sm-3 mt-10"
    >
      <v-card-title>
        {{ $t('managedStartups') }}
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          class="text-none mr-2"
          @click="modalAddManagedStartup = true"
        >
          <v-icon small class="mr-2">
            fas fa-plus-circle
          </v-icon>
          {{ $t('addStartup') }}
        </v-btn>
        <v-btn
          color="info"
          class="text-none"
          @click="getManagedBrands"
        >
          <v-icon small>
            fas fa-redo
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="searchManagedBrand"
          append-icon="fas fa-search"
          :label="`${$t('Search')}`"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headersManagedBrands"
          :items="managedStartups"
          :search="searchManagedBrand"
          :footer-props="{ itemsPerPageOptions: [15, 50, 100, -1] }"
          :options="optionsManagedBrands"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div @click="viewManagedWarehouse(item._id)" class="cursor-pointer">
              {{ item.name }}
            </div>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="primary"
              @click="viewManagedWarehouse(item._id)"
              class="white--text"
              :title="$t('view')"
            >
              <v-icon small>
                fas fa-eye
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.created`]="{ item }">
            <div @click="viewManagedWarehouse(item._id)" class="cursor-pointer">
              {{ $d(new Date(item.created), 'long') }}
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <!-- ADD MANAGED STARTUP MODAL -->
    <!-- ADD MANAGED STARTUP MODAL -->
    <!-- ADD MANAGED STARTUP MODAL -->
    <v-dialog
      v-model="modalAddManagedStartup"
      persistent
      max-width="750px"
    >
      <v-card min-height="300px">
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          {{ $t('addNewStartup') }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :ripple="false"
            @click="modalAddManagedStartup = false"
          >
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <formAddress
            ref="managedStartupForm"
            :address="managedStartup"
            :restricted-country-list="['FR']"
            :is-valid-v-a-t="isValidVAT"
            :show-first-name="false"
            :show-last-name="false"
            :show-state="false"
            :show-phone-number="true"
            :required-company="true"
            :required-zip-code="true"
            :required-phone-number="true"
            :required-v-a-tnumber="true"
            :allow-to-configure-v-a-t-later="true"
            @validateVAT="validateVAT"
            @updated="updateManagedStartup"
          ></formAddress>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="managedStartupLoading"
            :loading="managedStartupLoading"
            class="text-capitalize"
            color="success"
            @click="addManagedStartup"
          >
            <v-icon class="mr-1" small>
              fas fa-check
            </v-icon>
            {{ $t('Submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END MANAGED STARTUP MODAL -->
    <!-- END MANAGED STARTUP MODAL -->
  </div>
</template>

<style lang="scss" src="./pageAdministrationManagedStartups.scss"></style>;

<script>
import { EventBus, ApiErrorParser, FormAddress } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageAdministrationManagedStartups.i18n.json');

export default {
  name: 'PageAdministrationManagedStartups',
  components: { FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      brandUserRolesErrorMessage: null,
      disabled: true,

      /**
       * Startup manager feature
       */
      headersManagedBrands: null,
      managedStartup: new this.$BcmModel.Address(),
      managedStartupLoading: false,
      managedStartups: [],
      modalAddManagedStartup: false,
      optionsManagedBrands: { sortBy: ['created'], sortDesc: [true] },
      searchManagedBrand: '',
      isValidVAT: false,
    };
  },
  created() {
    if (!this.$appConfig.brand) {
      EventBus.$emit('reloadBrand');
    }
    this.headersManagedBrands = [
      { text: this.$t('name'), value: 'name' },
      { text: this.$t('created'), value: 'created' },
      {
        text: this.$t('action'),
        value: 'action',
        sortable: false,
        align: 'center',
      },
    ];
    /**
     * GET BRAND USER ROLES
     */
    this.getBrandUserRoles();
    /**
     * GET MANAGED BRANDS (if feature enabled)
     */
    if (this.$appConfig.brand.features.startup && this.$appConfig.brand.features.startup.isManager) {
      this.getManagedBrands();
    }
  },
  methods: {
    /**
     * GET BRAND USER ROLES
     */
    getBrandUserRoles() {
      this.$apiInstance
        .getBrandUserRoles(
          this.$appConfig.currentBrand,
          this.$appConfig.user._id
        )
        .then(
          (brandUserRolesData) => {
            this.brandUserRoles = brandUserRolesData;
            if (this.brandUserRoles.includes('admin')) {
              this.disabled = false;
            } else {
              this.brandUserRolesAdminAccessErrorMessage = this.$t(
                'needToBeAdmin'
              );
            }
          },
          (error) => {
            this.brandUserRolesErrorMessage = ApiErrorParser.parser(error);
          }
        );
    },
    /**
     * GET MANAGED BRANDS
     */
    getManagedBrands() {
      this.$apiInstance.getManagedBrands(this.$appConfig.currentBrand).then(
        (data) => {
          this.managedStartups = data;
        },
        (error) => {
          this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
        }
      );
    },
    /**
     * Validate VAT
     */
    async validateVAT(countryCode, vat) {
      this.isValidVAT = null;
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    },
    /**
     * ADD MANAGED STARTUP
     */
    addManagedStartup() {
      if (this.$refs.managedStartupForm.validate()) {
        this.managedStartupLoading = true;
        if(!this.managedStartup.VATnumber) { // If null, VAT is not added and will be consider to configured later
          delete this.managedStartup.VATnumber;
        }
        this.$apiInstance
          .registerManagedStartupBrand(
            this.$appConfig.currentBrand,
            this.managedStartup
          )
          .then(
            () => {
              this.modalAddManagedStartup = false;
              this.managedStartup = new this.$BcmModel.Address();
              this.getManagedBrands();
              EventBus.$emit('refreshAuthToken');
            },
            (error) => {
              this.$notification.notify('DANGER',this.$t(ApiErrorParser.parse(error)));  
            }
          )
          .finally(()=>{
            this.managedStartupLoading = false;
          });
      }
    },
    /**
     * VIEW MANAGED WAREHOUSE
     */
    viewManagedWarehouse(brandUUID) {
      EventBus.$emit('globalOrganizationSelectorOrganizationChanged', brandUUID);
    },
    updateManagedStartup(newAddress) {
      this.managedStartup = newAddress;
    }
  },
};
</script>
